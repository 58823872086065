@import '../../color.scss';

.base {
  width: 100%;
  height: 100vh;
  background: $background-050;

  .wrapper {
    max-width: 360px;
    margin: 0 auto;
    padding: 80px 16px;
    .box {
      max-width: 360px;
      margin: auto;
      border: 0.55px solid #ccc;
      border-radius: 0.25rem;
      box-shadow: 0 0 16px #ccc;
      background: rgba(255, 255, 255, 0.8);

      .tab {
        display: flex;
        justify-content: space-between;

        a {
          flex: 1;
          padding: 16px;
          border-bottom: 2px solid rgba(0, 0, 0, 0);
          text-align: center;
          text-decoration: none;
          color: #333;

          &.active {
            font-weight: bold;
          }

          &:not(.active) {
            background: $background-050;
            color: #333;
          }

          &:first-of-type {
            border-radius: 0.25rem 0 0 0;
          }

          &:last-of-type {
            border-radius: 0 0.25rem 0 0;
          }
        }
      }

      .signup,
      .login {
        margin: 16px;

        .input {
          margin: 8px 0;
          label {
            display: block;
            margin: 8px 0 2px;
          }

          input {
            margin: 2px 0 16px;
          }
        }

        .error {
          margin-bottom: 16px;
          padding: 8px;
          border: 1px solid #f5c6cb;
          color: #721c24;
          background: #f8d7da;
        }
      }
    }
  }
}
