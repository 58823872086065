.add {
  .modal-contents {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 11;

    padding-top: constant(safe-area-inset-top);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);

    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);

    transition: transform 0.2s ease-in-out;
    -webkit-transform: translate3d(0, 105%, 0);
    transform: translate3d(0, 105%, 0);
    -webkit-overflow-scrolling: touch;

    &.open {
      transition: transform 0.4s ease;
      -webkit-transform: translate3d(0, 0%, 0);
      transform: translate3d(0, 0%, 0);
      box-shadow: 6px 0 25px rgba(0, 0, 0, 0.15);
    }

    header {
      position: fixed;
      width: 100%;
      height: 44px;
      border-bottom: 0.55px solid rgba(0, 0, 0, 0.2);
      background: #fff;
      z-index: 14;

      display: flex;
      justify-content: center;
      align-items: center;

      h2 {
        font-size: 17px;
        font-weight: bold;
      }

      .cancel,
      .add {
        position: fixed;
        width: auto;
        height: 44px;
        color: rgba(10, 132, 255, 1);
        font-size: 16px;
        cursor: pointer;

        background: transparent;
        border: none;
        outline: none;
        padding: 0;
        appearance: none;

        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          color: rgba(10, 132, 255, 0.8);
        }

        &:active {
          color: rgba(10, 132, 255, 0.6);
        }

        label {
          cursor: pointer;
        }
      }

      .cancel {
        left: 12px;
      }

      .add {
        right: 12px;
        font-weight: bold;

        &:disabled {
          label {
            color: rgba(243, 115, 53, 0.2);
          }
        }
      }
    }

    .contents {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      margin: 44.547px 0 0;
      z-index: 12;

      padding-top: constant(safe-area-inset-top);
      padding-bottom: constant(safe-area-inset-bottom);
      padding-left: constant(safe-area-inset-left);
      padding-right: constant(safe-area-inset-right);

      padding-top: env(safe-area-inset-top);
      padding-bottom: env(safe-area-inset-bottom);
      padding-left: env(safe-area-inset-left);
      padding-right: env(safe-area-inset-right);

      .contents-inner-modal {
        & > div {
          padding: 0 8px;

          input {
            margin: 8px 0;
          }

          &.send {
            padding: 8px;
          }
        }
      }
    }
  }
}
