.settings {
  .logout {
    padding: 8px;
  }
  .userinfo {
    span:first-child {
      display: inline-block;
      color: #666;
      font-size: 0.9rem;
      min-width: 100px;
      text-align: right;
    }

    span:last-child {
      display: inline-block;
      margin-left: 8px;
    }
  }
}
