.header {
  header {
    width: 100%;
    height: 50px;
    border-bottom: 0.55px solid #ccc;

    display: flex;
    align-items: center;

    .menu-button {
      margin: 0 10px;

      button {
        width: 30px;
        height: 30px;
        margin: 0;
        padding: 0;
        border-radius: 15px;
        background: gray;
      }
    }

    h2 {
      margin: 0px;
    }
  }

  .menu-background {
    z-index: 99;
    &.close {
      display: none;
    }
    &.open {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .menu {
    &.close {
      display: none;
    }
    &.open {
      position: absolute;
      top: 50px;
      left: 10px;
      width: 220px;
      border-radius: 0.25rem;
      background: #fff;
      z-index: 100;
      box-shadow: rgba(0, 61, 111, 0.25) 0px 3px 12px;
    }

    ul {
      li {
        a,
        .link {
          display: block;
          padding: 10px;
          text-decoration: none;
          color: #333;
          font-size: 14px;
          cursor: pointer;

          &:hover {
            background: #eee;
          }
        }
      }

      .divider {
        border-top: 0.55px solid #eee;
      }
    }
  }
}
