@import './color.scss';

body {
  margin: 0;
  padding: 0;
  line-height: 1.6;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium',
    meiryo, sans-serif;
  color: #333;
}

html {
  padding-top: constant(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);

  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}

*:not(input) {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

a {
  -webkit-touch-callout: none;
}

input[type='text'],
input[type='address'],
input[type='password'],
input[type='date'],
input[type='time'],
textarea {
  -webkit-appearance: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium',
    meiryo, sans-serif;
}

input[type='text'],
input[type='password'],
input[type='date'] {
  -webkit-appearance: none;
  display: block;
  width: calc(100% - 20px - 2px);
  margin: 16px 0;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  color: #333;
  font-size: 17px;
  font-family: -apple-system, BlinkMacSystemFont, 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium',
    meiryo, sans-serif;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &::placeholder {
    color: #999;
  }

  &:focus {
    border: solid 1px $main-100;
    box-shadow: 0 0 0 0.2rem $main-025;
    outline: 0;
  }

  &:active {
    border: solid 1px $main-100;
  }
}

textarea {
  -webkit-appearance: none;
  display: block;
  width: calc(100% - 20px - 2px);
  height: 6em;
  margin: 0 auto;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  color: #333;
  font-size: 17px;
  font-family: -apple-system, BlinkMacSystemFont, 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', '游ゴシック Medium',
    meiryo, sans-serif;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus {
    border: solid 1px $main-100;
    box-shadow: 0 0 0 0.2rem $main-025;
    outline: 0;
  }

  &:active {
    border: solid 1px $main-100;
  }
}

button {
  display: block;
  width: 100%;
  height: 44px;
  padding: 6px 18px;
  border: none;
  border-radius: 0.25rem;
  background: $main-100;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  transition: all ease-in-out 0.2s;

  &:focus {
    box-shadow: 0 0 0 0.2rem $main-025;
  }

  &:hover,
  &:active,
  &:focus {
    background: $main-080;
    transition: all ease-in-out 0.1s;
    color: #fff;
  }

  &:active {
    background: $main-060;
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 0.2rem $main-025;
  }

  &:disabled {
    color: #888;
    background: #f8f8f8;
    cursor: default;
  }
}
