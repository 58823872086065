.home {
  .add {
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      width: auto;
    }
  }

  ul.diary {
    li a {
      display: block;
      padding: 8px;
      text-decoration: none;
      color: #333;

      &:hover {
        background: #eee;
      }

      span {
        font-size: 0.8rem;
        color: gray;
      }

      p {
        margin: 0;
      }
    }
  }
}
