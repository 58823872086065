.detail {
  .list {
    padding: 8px;
    .information {
      display: flex;
      align-items: center;

      label {
        margin-right: 8px;
        font-size: 0.8rem;
        color: #666;
      }
    }

    .content {
      margin: 16px 0;
    }
  }
}
